<template>
  <section class="fade-in">
    <div class="profile">
      <div class="profile-inner">
        <img src="/assets/imgs/profile_dark.png" alt="Alex Cerezo" class="profile-picture">
      </div>
      <div class="profile-content">
        <p class="text hero">
          Design technologist specialized in systems that help people. User focus. Problem-solver. Advocate of exceptional design.
        </p>
        <p class="text large">
          Great design is where user needs and business goals meet. In my 10+ years of experience I've been partner with Researchers, Designers and Developers to deliver crafted products to millions of users.
          <br /><br />
          I constantly seek opportunities for personal growth. In everything I do, I aim to foster an environment where people feel valued, and everyone can benefit from each other's strengths.
          <br /><br />
          I'm deeply passionate about solving complex problems with creativity and empathy, whether it's simplifying workflows, enhancing user experiences or exploring emerging technologies.
          <br /><br />
          Outside work I love playing (and studying) video-games, running after a piece of plastic in any Ultimate Frisbee tournament and traveling as much as I can.
        </p>
      </div>
    </div>
  </section>
</template>